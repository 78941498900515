import {
  Money,
  zero,
} from "@heritageholdings/lib-commons-finance/lib/units/money";
import {
  GridAggregationFunction,
  GridComparatorFn,
} from "@mui/x-data-grid-premium";

export const moneySumAggregationLabel = "moneySumAggregation" as const;

/**
 * Aggregation function for `Money` values.
 */
export const moneySumAggregation: GridAggregationFunction<Money, Money | null> =
  {
    apply: (params) => {
      if (params.values.length === 0) return null;

      const sum = params.values.reduce(
        (acc, next) => (acc ?? zero).plus(next ?? zero),
        zero,
      );

      return sum;
    },
    label: moneySumAggregationLabel,
    columnTypes: ["custom"],
  };

/**
 * Comparator function for `Money` values.
 */
export const moneySorter: GridComparatorFn<Money> = (v1, v2) => {
  const value1 = v1 ?? zero;
  const value2 = v2 ?? zero;

  return value1.minus(value2).toNormalizedAmount();
};
