import React, { useMemo } from "react";
import { SingleAssetDocument } from "../data/SignleAssetDocument";
import { SingleAssetContract } from "../data/SingleAssetContract";
import {
  DocumentLinkStructure,
  TableColumn,
  tableFallbackValue,
} from "../../../core/v2/Table/Table";
import { match } from "ts-pattern";
import { DocumentType } from "../../../../generated/urql";
import { formatDateDisplay1 } from "../../../../utils/date";
import { DocumentsTable } from "../../../core/v2/DocumentsTable/DocumentsTable";
import { useGenerateDocumentsTableLink } from "../../../../hooks/useGenerateDocumensTableLink";

type AssetDocumentsRow = {
  id: string;
  title: string;
  type: "overview" | "platform" | "contracts";
  createdOn?: Date;
  link?: DocumentLinkStructure;
};

type Props = {
  documents: ReadonlyArray<SingleAssetDocument>;
  contracts: ReadonlyArray<SingleAssetContract>;
};

/**
 * List all the documents related to an asset.
 */
export const AssetDocuments: React.FC<Props> = ({ documents, contracts }) => {
  const generateLinkKind = useGenerateDocumentsTableLink();

  const columns = useMemo<Array<TableColumn<AssetDocumentsRow>>>(
    () => [
      {
        headerName: "Title",
        field: "title",
        flex: 3,
      },
      {
        headerName: "Type",
        field: "type",
        valueFormatter: (value) =>
          match<unknown, string>(value)
            .with("overview", () => "Overview")
            .with("platform", () => "Platform")
            .with("contracts", () => "Contract")
            .otherwise(() => tableFallbackValue),
      },
      {
        headerName: "Created on",
        field: "createdOn",
        valueFormatter: (value) =>
          value instanceof Date
            ? formatDateDisplay1(value)
            : tableFallbackValue,
      },
    ],
    [],
  );

  const documentsRows = useMemo<Array<AssetDocumentsRow>>(
    () =>
      documents.map((document) => ({
        id: document.id,
        title: document.name,
        type: match<DocumentType, AssetDocumentsRow["type"]>(document.type)
          .with(DocumentType.Overview, () => "overview")
          .with(DocumentType.Platform, () => "platform")
          .exhaustive(),
        createdOn: document.approvedAt
          ? new Date(document.approvedAt)
          : undefined,
        link: generateLinkKind(document),
      })),
    [documents, generateLinkKind],
  );

  const contractsRows = useMemo<Array<AssetDocumentsRow>>(
    () =>
      contracts.map((contract) => ({
        id: contract.id,
        title: contract.name,
        type: "contracts",
        createdOn: contract.approvedAt
          ? new Date(contract.approvedAt)
          : undefined,
        link: generateLinkKind(contract),
      })),
    [contracts, generateLinkKind],
  );

  const rows = useMemo(
    () => [...documentsRows, ...contractsRows],
    [documentsRows, contractsRows],
  );

  return <DocumentsTable columns={columns} rows={rows} />;
};
