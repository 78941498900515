import React, { useMemo } from "react";
import { readonlyArray as RA, option as O, function as F } from "fp-ts";
import { useQuarterSelectManager } from "../QuarterSelect/QuarterSelect";
import * as U_Show from "@heritageholdings/lib-commons-finance/lib/show";
import { TableColumn } from "../../../core/v2/Table/Table";
import { SkeletonLoader } from "../../../core/v2/Loader/SkeletonLoader";
import { useAssetUrl } from "../../../../hooks/useAssetUrl";
import { formatCutoffDate, getTodayCutoff } from "../../../../utils/cutoff";
import { PageTable } from "../../../core/v2/PageTable/PageTable";
import { portfolioEmptyQuarterText } from "../PortfolioOverview/PortfolioOverview";
import { useRecoilValue } from "recoil";
import { enablePortfolioAudit } from "../../../../state/developer";
import { parseMoneyCurrency } from "../../../../utils/data/SimpleMoney";
import { GridRowId, GridApiCommon } from "@mui/x-data-grid-premium";
import { useInvestor } from "../../../../hooks/useInvestor";
import { generateQuarterlyReportLink } from "../../../../utils/documents";
import { useRetrieveCutoffs } from "../../../../hooks/useRetrieveCutoffs";

type PortfolioAssetsRow = {
  firstCapitalCallDate: string | undefined;
  committedCapitalCalledPercent: number;
  sourcePaidInUsd: number;
  auditTrailJson: string;
  currency: string;

  id: string;
  fundId: string;
  fund: string;
  commitment: number;
  distributed: number;
  dpi: number;
  mom: number;
  irr: number;
  paidIn: number;
  profit: number;
  nav: number;
};

const retrieveRow = (
  api: React.MutableRefObject<GridApiCommon>,
  id: GridRowId | undefined,
) =>
  F.pipe(
    id,
    O.fromNullable,
    O.chain((id) => O.fromNullable(api.current.getRow<PortfolioAssetsRow>(id))),
  );

/**
 * Display the `assets` tab of the portfolio.
 */
export const PortfolioAssets: React.FC = () => {
  const investor = useInvestor();
  const { selectedQuarter, maybePortfolioSituation, loading } =
    useQuarterSelectManager();
  const { isDraftCutoff } = useRetrieveCutoffs();

  const isTodayQuarter = useMemo(
    () => selectedQuarter === formatCutoffDate(getTodayCutoff()),
    [selectedQuarter],
  );

  const getFundUrl = useAssetUrl({ fromPortfolio: true });
  const portfolioV2AuditEnabled = useRecoilValue(enablePortfolioAudit);

  const columns = useMemo<Array<TableColumn<PortfolioAssetsRow>>>(
    () => [
      {
        headerName: "Fund",
        field: "fund",
        flex: 3,
        minWidth: 200,
        customColumnKind: {
          kind: "internalLink",
          generateLink: ({ row }) => (row ? getFundUrl(row.fundId) : "#"),
        },
      },
      {
        headerName: "Commitment",
        field: "commitment",
        minWidth: 150,
        valueFormatter: (value, { id }, _, api) =>
          F.pipe(
            retrieveRow(api, id),
            O.map(({ currency }) =>
              U_Show.currencyNoDecimal(parseMoneyCurrency(currency)).show(
                value as number,
              ),
            ),
            O.getOrElse(() => ""),
          ),
      },
      {
        headerName: "Paid-in",
        field: "paidIn",
        minWidth: 150,
        customColumnKind: {
          kind: "valueWithWarning",
          generateMessage({ row: maybeRow }) {
            if (!maybeRow) return undefined;

            const currency = maybeRow.currency;

            const sourcePaidInUsd =
              maybeRow.sourcePaidInUsd > 0
                ? U_Show.currencyNoDecimal(parseMoneyCurrency(currency)).show(
                    maybeRow.sourcePaidInUsd,
                  )
                : undefined;

            if (!sourcePaidInUsd) return undefined;

            return `This allocation originates from a secondary transaction, with the paid-in amount comprising the payment made by previous owners of this allocation. (${sourcePaidInUsd})`;
          },
        },
        valueFormatter: (value, { id }, _, api) => {
          const maybeRow = id
            ? api.current.getRow<PortfolioAssetsRow>(id)
            : null;

          if (!maybeRow) return null;

          const currency = maybeRow.currency;

          return U_Show.currencyNoDecimal(parseMoneyCurrency(currency)).show(
            value as number,
          );
        },
      },
      {
        headerName: "Distributed",
        field: "distributed",
        valueFormatter: (value, { id }, _, api) =>
          F.pipe(
            retrieveRow(api, id),
            O.map(({ currency }) =>
              U_Show.currencyNoDecimal(parseMoneyCurrency(currency)).show(
                value as number,
              ),
            ),
            O.getOrElse(() => ""),
          ),
      },
      {
        headerName: isTodayQuarter ? "est. Nav" : "Nav",
        field: "nav",
        minWidth: 150,
        valueFormatter: (value, { id }, _, api) =>
          F.pipe(
            retrieveRow(api, id),
            O.map(({ currency }) =>
              U_Show.currencyNoDecimal(parseMoneyCurrency(currency)).show(
                value as number,
              ),
            ),
            O.getOrElse(() => ""),
          ),
      },
      {
        headerName: "DPI",
        field: "dpi",
        valueFormatter: (value) => U_Show.dpi.show(value as number),
      },
      {
        headerName: "MOM",
        field: "mom",
        valueFormatter: (value) => U_Show.fundMom.show(value as number),
      },
      {
        headerName: "IRR",
        field: "irr",
        valueFormatter: (value, { id }, _, api) =>
          F.pipe(
            retrieveRow(api, id),
            O.map(
              ({
                profit,
                mom,
                firstCapitalCallDate,
                committedCapitalCalledPercent,
              }) =>
                U_Show.irrWithNM({
                  profit,
                  firstCapitalCallDate,
                  committedCapitalCalledPercent,
                  mom,
                }).show(value as number),
            ),
            O.getOrElse(() => ""),
          ),
      },
      {
        headerName: isTodayQuarter ? "est. Profit" : "Profit",
        field: "profit",
        minWidth: 150,
        valueFormatter: (value, { id }, _, api) =>
          F.pipe(
            retrieveRow(api, id),
            O.map(({ nav, currency }) =>
              U_Show.currencyFundProfit(parseMoneyCurrency(currency))(nav).show(
                value as number,
              ),
            ),
            O.getOrElse(() => ""),
          ),
      },
      {
        headerName: "Audit",
        field: "auditTrailJson",
        customColumnKind: {
          kind: "portfolioAudit",
          generateJsonAudit: ({ value }) =>
            typeof value === "string" ? value : JSON.stringify({}),
        },
      },
    ],
    [getFundUrl, isTodayQuarter],
  );

  const rows = useMemo<Array<PortfolioAssetsRow>>(() => {
    if (O.isNone(maybePortfolioSituation)) return [];

    const computedRows: Array<PortfolioAssetsRow> = F.pipe(
      maybePortfolioSituation.value.byAllocation,
      RA.zip(maybePortfolioSituation.value.annotatedByAllocation),
      RA.map(([psfa, annotated]) => ({
        firstCapitalCallDate:
          psfa.allocation.asset.firstCapitalCallDate ?? undefined,

        committedCapitalCalledPercent:
          psfa.allocation.asset.committedCapitalCalledPercent,

        sourcePaidInUsd: psfa.sourcePaidInUsd,

        auditTrailJson: annotated?.trailJson_UNSTABLE ?? JSON.stringify({}),
        currency: psfa.allocation.currency,

        id: `${psfa.allocation.asset.id}-${psfa.allocation.allocationDate}-${psfa.allocation.currency}`,
        fund: psfa.allocation.asset.name,
        fundId: psfa.allocation.asset.id,
        commitment: psfa.allocation.amountUsd,
        distributed: psfa.distributedUsd,
        dpi: psfa.dpi,
        mom: psfa.mom,
        irr: psfa.irr,
        paidIn: psfa.paidInUsd,
        profit: psfa.profitUsd,
        nav: psfa.navUsd,
      })),
      RA.toArray,
    );

    return computedRows;
  }, [maybePortfolioSituation]);

  return (
    <PageTable
      title="Assets"
      kind="table"
      loading={loading}
      rows={rows}
      columns={columns}
      loaderComponent={<SkeletonLoader size={{ height: "table" }} />}
      customEmptyText={portfolioEmptyQuarterText}
      visibilityModel={{
        auditTrailJson: portfolioV2AuditEnabled,
      }}
      exportLink={
        investor?.id &&
        selectedQuarter &&
        !isDraftCutoff(new Date(selectedQuarter))
          ? generateQuarterlyReportLink(investor.id, new Date(selectedQuarter))
          : undefined
      }
      defaultPagesIndex={1}
      quarterSelect
      downloadCsv
    />
  );
};
