import React, { useMemo } from "react";
import { useRetrieveCutoffs } from "../../../../hooks/useRetrieveCutoffs";
import { PageTable } from "../../../core/v2/PageTable/PageTable";
import {
  DocumentLinkStructure,
  TableColumn,
} from "../../../core/v2/Table/Table";
import { formatCutoffDate } from "../../../../utils/cutoff";
import { useInvestor } from "../../../../hooks/useInvestor";
import { formatDateQuarterExtended } from "../../../../utils/date";
import { generateQuarterlyReportLink } from "../../../../utils/documents";

type QuarterlyReportsRow = {
  id: string;
  quarter: Date;
  link: DocumentLinkStructure;
};

export const QuarterlyReportsDocuments: React.FC = () => {
  const { confirmedCutoffs } = useRetrieveCutoffs();
  const investor = useInvestor();

  const columns = useMemo<Array<TableColumn<QuarterlyReportsRow>>>(
    () => [
      {
        headerName: "Quarter",
        field: "quarter",
        valueFormatter: (value) => formatDateQuarterExtended(value as Date),
      },
    ],
    [],
  );

  const rows = useMemo(() => {
    const computedRows = Array<QuarterlyReportsRow>();
    const investorId = investor?.id;

    if (!investorId) return computedRows;

    for (const cutoff of confirmedCutoffs) {
      const formattedCutoffDate = formatCutoffDate(cutoff);
      computedRows.push({
        id: formattedCutoffDate,
        quarter: cutoff,
        link: {
          kind: "download",
          link: generateQuarterlyReportLink(investorId, cutoff),
        },
      });
    }

    return computedRows;
  }, [confirmedCutoffs, investor]);

  return (
    <PageTable
      kind="documents-table"
      title="Quarterly reports"
      columns={columns}
      rows={rows}
    />
  );
};
