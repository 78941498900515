import React, { useMemo } from "react";
import { SingleDocument } from "../data/SingleDocument";
import {
  DocumentLinkStructure,
  TableColumn,
  TableGroupingColumn,
  tableFallbackValue,
} from "../../../core/v2/Table/Table";
import { useGenerateDocumentsTableLink } from "../../../../hooks/useGenerateDocumensTableLink";
import { formatDateDisplay1 } from "../../../../utils/date";
import { PageTable } from "../../../core/v2/PageTable/PageTable";

type InvestmentsRow = {
  path: Array<string>;
  id: string;
  title: string;
  fund: string;
  createdOn?: Date;
  link?: DocumentLinkStructure;
};

type Props = {
  documents: ReadonlyArray<SingleDocument>;
};

/**
 * List all the documents related to an asset in the Data Room view.
 */
export const InvestmentsDocuments: React.FC<Props> = ({ documents }) => {
  const generateLinkKind = useGenerateDocumentsTableLink();
  const columns = useMemo<Array<TableColumn<InvestmentsRow>>>(
    () => [
      {
        headerName: "Created on",
        field: "createdOn",
        minWidth: 150,
        valueFormatter: (value) =>
          value instanceof Date
            ? formatDateDisplay1(value)
            : tableFallbackValue,
      },
    ],
    [],
  );

  const { rows, assetsNamesRecord, documentsNamesRecord } = useMemo(() => {
    const rows = Array<InvestmentsRow>();
    const assetsNamesRecord: Record<string, string> = {};
    const documentsNamesRecord: Record<string, string> = {};

    for (const doc of documents) {
      const asset = doc.assets[0];

      if (!asset) continue;

      rows.push({
        path: [asset.id, doc.id],
        id: doc.id,
        title: doc.name,
        fund: asset.name,
        createdOn: doc.approvedAt ? new Date(doc.approvedAt) : undefined,
        link: generateLinkKind(doc),
      });

      assetsNamesRecord[asset.id] = asset.name;
      documentsNamesRecord[doc.id] = doc.name;
    }

    return { rows, assetsNamesRecord, documentsNamesRecord };
  }, [documents, generateLinkKind]);

  const groupingColumn = useMemo<TableGroupingColumn<InvestmentsRow>>(
    () => ({
      flex: 3,
      minWidth: 200,
      headerName: "Funds",
      valueFormatter: (value) => {
        // If the value is not `undefined` then this is a
        // group and we should format it.
        if (typeof value !== "undefined") {
          return typeof value === "string" && assetsNamesRecord[value]
            ? assetsNamesRecord[value]
            : tableFallbackValue;
        }

        // If otherwise the value IS `undefined` then this is
        // a normal row, and we should return `undefined`.
        return value;
      },
      formatLeafValue: (value) =>
        typeof value === "string" ? documentsNamesRecord[value] : value,
    }),
    [assetsNamesRecord, documentsNamesRecord],
  );

  return (
    <PageTable
      kind="documents-table"
      title="Investments"
      columns={columns}
      rows={rows}
      groupingColumn={groupingColumn}
      defaultGroupsExpanded
      treeData
    />
  );
};
