import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
} from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { Box } from "../../../core/v2/Box/Box";
import { Input } from "../../../core/v2/Input/Input";
import { FormControlLabel, Radio, RadioGroup, Stack } from "@mui/material";
import { Select } from "../../../core/v2/Select/Select";
import { useInvestor } from "../../../../hooks/useInvestor";

const currencySchema = z.union([z.literal("EUR"), z.literal("USD")]);

const bankAccountFormDataSchema = z.intersection(
  z
    .object({
      iban: z.string().max(34).optional(),
      accountNumber: z.string().max(30).optional(),
      swift: z.string().max(11).optional(),

      code: z.union([z.literal("iban"), z.literal("accountNumber")]),
    })
    // If we have the `iban`, the `accountNumber` and `swift` are not required,
    // and vice versa.
    .refine((data) => (data.code === "iban" ? !!data.iban : true), {
      path: ["iban"],
    })
    .refine(
      (data) => (data.code === "accountNumber" ? !!data.accountNumber : true),
      {
        path: ["accountNumber"],
      },
    )
    .refine((data) => (data.code === "accountNumber" ? !!data.swift : true), {
      path: ["swift"],
    }),

  z.object({
    bankName: z.string().min(1),
    bankAddress: z.string().min(1),
    accountHolder: z.string().min(1),
    currency: currencySchema,
    vehicleId: z.string().min(1),
  }),
);

export type BankAccountFormData = z.infer<typeof bankAccountFormDataSchema>;

type Props = {
  initialValues?: Partial<BankAccountFormData>;
  onSubmit: (data: BankAccountFormData) => void;
};

export type BankAccountFormRef = {
  triggerSubmit: () => void;
};

export const BankAccountForm = forwardRef<BankAccountFormRef, Props>(
  ({ initialValues, onSubmit }, ref) => {
    const investor = useInvestor();
    const investmentVehicles = useMemo(
      () => investor?.investmentVehicles ?? [],
      [investor],
    );

    const { control, handleSubmit, watch, setValue } =
      useForm<BankAccountFormData>({
        mode: "onBlur",
        resolver: zodResolver(bankAccountFormDataSchema),
        defaultValues: {
          currency: "EUR",
          code: "iban",
          vehicleId: investmentVehicles[0]?.id,
          ...initialValues,
        },
      });

    useImperativeHandle(ref, () => ({
      triggerSubmit: () => {
        handleSubmit(onSubmit)();
      },
    }));

    const watchedCode = watch("code");

    // Reset the values when the code changes.
    useEffect(() => {
      setValue("iban", undefined);
      setValue("accountNumber", undefined);
      setValue("swift", undefined);
    }, [watchedCode, setValue]);

    if (!investor) return null;

    return (
      <Box width="100%">
        <Stack width="100%" direction="column" gap={2}>
          <Box>
            <Controller
              control={control}
              name="code"
              render={({ field }) => (
                <RadioGroup row {...field}>
                  <FormControlLabel
                    value={"iban" satisfies BankAccountFormData["code"]}
                    control={<Radio />}
                    label="IBAN"
                  />
                  <FormControlLabel
                    value={
                      "accountNumber" satisfies BankAccountFormData["code"]
                    }
                    control={<Radio />}
                    label="Account Number"
                  />
                </RadioGroup>
              )}
            />
          </Box>

          {watchedCode === "iban" ? (
            <Controller
              control={control}
              name="iban"
              render={({ field, fieldState }) => (
                <Input
                  {...field}
                  label="IBAN"
                  error={!!fieldState.error}
                  required
                />
              )}
            />
          ) : undefined}

          {watchedCode === "accountNumber" ? (
            <>
              <Controller
                control={control}
                name="accountNumber"
                render={({ field, fieldState }) => (
                  <Input
                    {...field}
                    label="Account Number"
                    error={!!fieldState.error}
                    required
                  />
                )}
              />

              <Controller
                control={control}
                name="swift"
                render={({ field, fieldState }) => (
                  <Input
                    {...field}
                    label="SWIFT"
                    error={!!fieldState.error}
                    required
                  />
                )}
              />
            </>
          ) : undefined}

          <Controller
            control={control}
            name="bankName"
            render={({ field, fieldState }) => (
              <Input
                {...field}
                label="Bank Name"
                error={!!fieldState.error}
                required
              />
            )}
          />

          <Controller
            control={control}
            name="bankAddress"
            render={({ field, fieldState }) => (
              <Input
                {...field}
                label="Bank Address"
                error={!!fieldState.error}
                required
              />
            )}
          />

          <Controller
            control={control}
            name="accountHolder"
            render={({ field, fieldState }) => (
              <Input
                {...field}
                label="Account Holder"
                error={!!fieldState.error}
                required
              />
            )}
          />

          <Controller
            control={control}
            name="currency"
            render={({ field, fieldState }) => (
              <Select
                value={field.value}
                onChange={(v) => {
                  const parsedValue = currencySchema.safeParse(v);

                  if (parsedValue.success) field.onChange(parsedValue.data);
                }}
                label="Currency"
                options={[
                  {
                    kind: "value",
                    key: "EUR",
                    label: "EUR",
                    value: "EUR" as const,
                  },
                  {
                    kind: "value",
                    key: "USD",
                    label: "USD",
                    value: "USD" as const,
                  },
                ]}
                error={!!fieldState.error}
                required
              />
            )}
          />

          {investmentVehicles.length > 1 ? (
            <Controller
              control={control}
              name="vehicleId"
              render={({ field, fieldState }) => (
                <Select
                  value={field.value}
                  onChange={(v) => {
                    field.onChange(v);
                  }}
                  label="Partnership"
                  options={investor.investmentVehicles.map((v) => ({
                    key: v.id,
                    label: v.name,
                    value: v.id,
                    kind: "value",
                  }))}
                  error={!!fieldState.error}
                  required
                />
              )}
            />
          ) : undefined}
        </Stack>
      </Box>
    );
  },
);
